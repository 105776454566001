/*draft styles. reorganize after approval*/

@import "search.less";

@media @mobile {
  nav.navigation.mobile-nav {
    transition: all .2s ease-out 0s;
    width: ~"calc(100% - 56px)";
    max-width: 320px;
    left: -100%;
    position: fixed;
    top: 68px;
    height: ~"calc(100% - 68px)";
    background: #fff;
    padding-top: 20px;
    box-shadow: 2px 0 2px rgba(0,0,0,.3);
    overflow-y: auto;
    will-change: left;

    a {
      display: block;
      font-size: 16px;
      height: 55px;
      line-height: 55px;
      text-align: left;
      color: #1f262d;
      font-family: @font-bebas;
      padding-left: 20px;

      &.w-border {
        border-bottom: 1px solid #efefef;
      }
    }

    &.show {
      transition: all .2s ease-out .1s;
      left: 0;
    }
  }

  .events-header {
    .filters-part {
      position: fixed;
      right: 65px;
      top: 60px;
      background: #fff;
      box-shadow: 0 2px 2px rgba(0,0,0,.3);
      z-index: 400;
      overflow: hidden;
      display: none;
      width: 250px;
      overflow-y: scroll;
      max-height: ~'calc(100% - 60px)';

      > div {
        float: none;
        width: 100%;
        padding: 0;
        border-bottom: 1px solid #efefef;
        text-align: left;
        position: relative;
        padding: 20px;


        &:last-child {
          border-bottom: 0;
        }

        span {
          display: inline;
          text-align: left;
          max-width: 100%;
        }
      }
      .filter-block .filter-options {
        position: static;
        box-shadow: none;
        width: 100%;
        -webkit-transform: translateX(0);
        transform: translateX(0);

        .close-select {
          display: none;
        }
      }
      .filter-block .filter-options.events-date input {
        position: absolute;
        z-index: -1;
        top: -100vh;
      }

      .pika-single.is-bound {
        display: block;
        position: relative;
        width: ~"calc(100% + 40px)"!important;
        box-shadow: none!important;
        margin: 0 -20px;
        border: none;
        padding-top: 0;
      }
    }

    .close-select {
      display: none;
    }

    .filter-block.active .filter-link:after {
      display: none;
    }
  }

  #fade-m {
    transition: background .2s ease-out 0s;
    position: fixed;
    top: 68px;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.2);
    z-index: 50;
  }

  .hide-on-mobile {
    display: none;
  }

  .sort-link {
    top: 8px;
    right: 56px;
    position: fixed;
    font-size: 22px;
    z-index: 301;
    padding: 14px;
    color: #fff!important;
    transition: all .3s ease-out;
  }

  .search-list .image:hover {
    z-index: 10;
  }
}