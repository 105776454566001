@font-face {
  font-family: 'drewaltizer';
  src: url('@{fonts-path}drewaltizer.eot?17721773');
  src:
          //url('@{fonts-path}drewaltizer.eot?17721773#iefix') format('embedded-opentype'),
  url('@{fonts-path}drewaltizer.woff?17721773') format('woff'),
  url('@{fonts-path}drewaltizer.ttf?17721773') format('truetype'),
  url('@{fonts-path}drewaltizer.svg?17721773#drewaltizer') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* cyrillic */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
  src: local('Oswald Light'), local('Oswald-Light'), url(https://fonts.gstatic.com/s/oswald/v16/TK3hWkUHHAIjg75-sh0Tvs9CE5Q.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
  src: local('Oswald Light'), local('Oswald-Light'), url(https://fonts.gstatic.com/s/oswald/v16/TK3hWkUHHAIjg75-sh0Tus9C.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  src: local('Oswald Regular'), local('Oswald-Regular'), url(https://fonts.gstatic.com/s/oswald/v16/TK3iWkUHHAIjg752HT8Ghe4.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  src: local('Oswald Regular'), local('Oswald-Regular'), url(https://fonts.gstatic.com/s/oswald/v16/TK3iWkUHHAIjg752GT8G.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  src: local('Oswald Bold'), local('Oswald-Bold'), url(https://fonts.gstatic.com/s/oswald/v16/TK3hWkUHHAIjg75-ohoTvs9CE5Q.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  src: local('Oswald Bold'), local('Oswald-Bold'), url(https://fonts.gstatic.com/s/oswald/v16/TK3hWkUHHAIjg75-ohoTus9C.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('@{fonts-path}helveticaneue.eot');
  src:
          //url('@{fonts-path}helveticaneue.eot#iefix') format('embedded-opentype'),
  url('@{fonts-path}helveticaneue.woff') format('woff'),
  url('@{fonts-path}helveticaneue.ttf') format('truetype'),
  url('@{fonts-path}helveticaneue.svg#helveticaneue') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('@{fonts-path}helveticaneuelight.eot');
  src:
          //url('@{fonts-path}helveticaneuelight.eot#iefix') format('embedded-opentype'),
  url('@{fonts-path}helveticaneuelight.woff') format('woff'),
  url('@{fonts-path}helveticaneuelight.ttf') format('truetype'),
  url('@{fonts-path}helveticaneuelight.svg#helveticaneue') format('svg');
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('@{fonts-path}helveticaneuebold.eot');
  src:
          //url('@{fonts-path}helveticaneuebold.eot#iefix') format('embedded-opentype'),
  url('@{fonts-path}helveticaneuebold.woff') format('woff'),
  url('@{fonts-path}helveticaneuebold.ttf') format('truetype'),
  url('@{fonts-path}helveticaneuebold.svg#helveticaneue') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Garamond';
  //src: url('@{fonts-path}adobegaramondproregular.eot');
  src:
          //url('@{fonts-path}adobegaramondproregular.eot#iefix') format('embedded-opentype'),
  url('@{fonts-path}adobegaramondproregular.woff') format('woff'),
  url('@{fonts-path}adobegaramondproregular.ttf') format('truetype'),
  url('@{fonts-path}adobegaramondproregular.svg#garamond') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Garamond';
  //src: url('@{fonts-path}adobegaramondproitalic.eot');
  src:
          //url('@{fonts-path}adobegaramondproitalic.eot#iefix') format('embedded-opentype'),
  url('@{fonts-path}adobegaramondproitalic.woff') format('woff'),
  url('@{fonts-path}adobegaramondproitalic.ttf') format('truetype'),
  url('@{fonts-path}adobegaramondproitalic.svg#garamond') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Bebas';
  src: url('@{fonts-path}bebas.eot');
  src:
          //url('@{fonts-path}bebas.eot#iefix') format('embedded-opentype'),
  url('@{fonts-path}bebas.woff') format('woff'),
  url('@{fonts-path}bebas.ttf') format('truetype'),
  url('@{fonts-path}bebas.svg#garamond') format('svg');
  font-weight: normal;
  font-style: normal;
}


[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "drewaltizer";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  text-align: center;
}

.icon-small-triangle:before { content: '\e800'; } /* '' */
.icon-instagram:before { content: '\e801'; } /* '' */
.icon-twitter:before { content: '\e802'; } /* '' */
.icon-facebook:before { content: '\e803'; } /* '' */
.icon-done:before { content: '\e804'; } /* '' */
.icon-down:before { content: '\e805'; } /* '' */
.icon-check:before { content: '\e806'; } /* '' */
.icon-top:before { content: '\e807'; } /* '' */
.icon-minus:before { content: '\e808'; } /* '' */
.icon-mail:before { content: '\e809'; } /* '' */
.icon-search:before { content: '\e80a'; } /* '' */
.icon-right:before { content: '\e80b'; } /* '' */
.icon-left:before { content: '\e80c'; } /* '' */
.icon-close:before { content: '\e80d'; } /* '' */
.icon-cancel:before { content: '\e80e'; } /* '' */
.icon-plus:before { content: '\e80f'; } /* '' */
.icon-star:before { content: '\e810'; } /* '' */
.icon-pen-small:before { content: '\e811'; } /* '' */
.icon-dots:before { content: '\e812'; } /* '' */
.icon-question:before { content: '\e813'; } /* '' */
.icon-print:before { content: '\e814'; } /* '' */
.icon-pen:before { content: '\e815'; } /* '' */
.icon-user:before { content: '\e816'; } /* '' */
.icon-pictures:before { content: '\e817'; } /* '' */
.icon-play:before { content: '\e818'; } /* '' */
.icon-diagramm:before { content: '\e819'; } /* '' */
.icon-nav:before { content: '\e81a'; } /* '' */
.icon-empty:before { content: '\e81b'; } /* '' */
.icon-phone:before { content: '\e81c'; } /* '' */
.icon-email:before { content: '\e81d'; } /* '' */
.icon-trash:before { content: '\e81e'; } /* '' */
.icon-search-2:before { content: '\e81f'; } /* '' */
.icon-tool:before { content: '\e820'; } /* '' */
.icon-folder:before { content: '\e821'; } /* '' */
.icon-info:before { content: '\e822'; } /* '' */
.icon-home:before { content: '\e823'; } /* '' */
.icon-arrow-right:before { content: '\e824'; } /* '' */
.icon-arrow-left:before { content: '\e825'; } /* '' */
.icon-up-left:before { content: '\e826'; } /* '' */
.icon-up-right:before { content: '\e827'; } /* '' */
.icon-home-star:before { content: '\e828'; } /* '' */
.icon-download-alt:before { content: '\e829'; } /* '' */
.icon-share:before { content: '\e82a'; } /* '' */
.icon-mail-full:before { content: '\e82b'; } /* '' */
.icon-pinterest:before { content: '\e82c'; } /* '' */
.icon-check-thin:before { content: '\e82d'; } /* '' */
.icon-lock:before { content: '\e82e'; } /* '' */
.icon-lock-open:before { content: '\e82f'; } /* '' */
.icon-eye:before { content: '\e830'; } /* '' */
.icon-eye-off:before { content: '\e831'; } /* '' */
.icon-newspaper:before { content: '\e832'; } /* '' */
.icon-floppy:before { content: '\e833'; } /* '' */
.icon-filter:before { content: '\e834'; } /* '' */
.icon-sort-name-up:before { content: '\e835'; } /* '' */
.icon-sort-name-down:before { content: '\e836'; } /* '' */
.icon-eraser:before { content: '\e837'; } /* '' */
.icon-leaf:before { content: '\e838'; } /* '' */
.icon-file:before { content: '\e839'; } /* '' */
.icon-cart:before { content: '\e83a'; } /* '' */
.icon-credit-card:before { content: '\e83b'; } /* '' */
.icon-requested:before { content: '\e83c'; } /* '' */
.icon-purchased:before { content: '\e83d'; } /* '' */
.icon-down-big:before { content: '\e83e'; } /* '' */
.icon-up-big:before { content: '\e83f'; } /* '' */
.icon-angle-down:before { content: '\e840'; } /* '' */
.icon-basket:before { content: '\e841'; } /* '' */
.icon-album:before { content: '\e842'; } /* '' */
.icon-date:before { content: '\e843'; } /* '' */
.icon-people:before { content: '\e844'; } /* '' */
.icon-zoom-in:before { content: '\e845'; } /* '' */
.icon-zoom-out:before { content: '\e846'; } /* '' */
.icon-a:before { content: '\e849'; } /* '' */
.icon-sw:before { content: '\e84a'; } /* '' */
.icon-download:before { content: '\e84b'; } /* '' */
.icon-share-1:before { content: '\e84c'; } /* '' */
.icon-spin4:before { content: '\e84d'; } /* '' */
.icon-spin6:before { content: '\e84e'; } /* '' */
.icon-crop:before { content: '\e84f'; } /* '' */
.icon-full:before { content: '\e850'; } /* '' */
.icon-warning-empty:before { content: '\e851'; } /* '' */
.icon-warning:before { content: '\e852'; } /* '' */