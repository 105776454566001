.clearfix,
.cf {
  *zoom: 1;
}
.clearfix:before,
.cf:before,
.clearfix:after,
.cf:after {
  display: table;
  content: "";
  line-height: 0;
}
.clearfix:after,
.cf:after {
  clear: both;
}
.flex {
  display: -webkit-flex;
  display: flex;
}
.flex-half {
  flex: 0 0 50%;
}
@font-face {
  font-family: 'drewaltizer';
  src: url('/media/fonts/drewaltizer.eot?17721773');
  src: url('/media/fonts/drewaltizer.woff?17721773') format('woff'), url('/media/fonts/drewaltizer.ttf?17721773') format('truetype'), url('/media/fonts/drewaltizer.svg?17721773#drewaltizer') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* cyrillic */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
  src: local('Oswald Light'), local('Oswald-Light'), url(https://fonts.gstatic.com/s/oswald/v16/TK3hWkUHHAIjg75-sh0Tvs9CE5Q.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
  src: local('Oswald Light'), local('Oswald-Light'), url(https://fonts.gstatic.com/s/oswald/v16/TK3hWkUHHAIjg75-sh0Tus9C.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  src: local('Oswald Regular'), local('Oswald-Regular'), url(https://fonts.gstatic.com/s/oswald/v16/TK3iWkUHHAIjg752HT8Ghe4.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  src: local('Oswald Regular'), local('Oswald-Regular'), url(https://fonts.gstatic.com/s/oswald/v16/TK3iWkUHHAIjg752GT8G.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  src: local('Oswald Bold'), local('Oswald-Bold'), url(https://fonts.gstatic.com/s/oswald/v16/TK3hWkUHHAIjg75-ohoTvs9CE5Q.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  src: local('Oswald Bold'), local('Oswald-Bold'), url(https://fonts.gstatic.com/s/oswald/v16/TK3hWkUHHAIjg75-ohoTus9C.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'HelveticaNeue';
  src: url('/media/fonts/helveticaneue.eot');
  src: url('/media/fonts/helveticaneue.woff') format('woff'), url('/media/fonts/helveticaneue.ttf') format('truetype'), url('/media/fonts/helveticaneue.svg#helveticaneue') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'HelveticaNeue';
  src: url('/media/fonts/helveticaneuelight.eot');
  src: url('/media/fonts/helveticaneuelight.woff') format('woff'), url('/media/fonts/helveticaneuelight.ttf') format('truetype'), url('/media/fonts/helveticaneuelight.svg#helveticaneue') format('svg');
  font-weight: lighter;
  font-style: normal;
}
@font-face {
  font-family: 'HelveticaNeue';
  src: url('/media/fonts/helveticaneuebold.eot');
  src: url('/media/fonts/helveticaneuebold.woff') format('woff'), url('/media/fonts/helveticaneuebold.ttf') format('truetype'), url('/media/fonts/helveticaneuebold.svg#helveticaneue') format('svg');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Garamond';
  src: url('/media/fonts/adobegaramondproregular.woff') format('woff'), url('/media/fonts/adobegaramondproregular.ttf') format('truetype'), url('/media/fonts/adobegaramondproregular.svg#garamond') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Garamond';
  src: url('/media/fonts/adobegaramondproitalic.woff') format('woff'), url('/media/fonts/adobegaramondproitalic.ttf') format('truetype'), url('/media/fonts/adobegaramondproitalic.svg#garamond') format('svg');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Bebas';
  src: url('/media/fonts/bebas.eot');
  src: url('/media/fonts/bebas.woff') format('woff'), url('/media/fonts/bebas.ttf') format('truetype'), url('/media/fonts/bebas.svg#garamond') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "drewaltizer";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
}
.icon-small-triangle:before {
  content: '\e800';
}
/* '' */
.icon-instagram:before {
  content: '\e801';
}
/* '' */
.icon-twitter:before {
  content: '\e802';
}
/* '' */
.icon-facebook:before {
  content: '\e803';
}
/* '' */
.icon-done:before {
  content: '\e804';
}
/* '' */
.icon-down:before {
  content: '\e805';
}
/* '' */
.icon-check:before {
  content: '\e806';
}
/* '' */
.icon-top:before {
  content: '\e807';
}
/* '' */
.icon-minus:before {
  content: '\e808';
}
/* '' */
.icon-mail:before {
  content: '\e809';
}
/* '' */
.icon-search:before {
  content: '\e80a';
}
/* '' */
.icon-right:before {
  content: '\e80b';
}
/* '' */
.icon-left:before {
  content: '\e80c';
}
/* '' */
.icon-close:before {
  content: '\e80d';
}
/* '' */
.icon-cancel:before {
  content: '\e80e';
}
/* '' */
.icon-plus:before {
  content: '\e80f';
}
/* '' */
.icon-star:before {
  content: '\e810';
}
/* '' */
.icon-pen-small:before {
  content: '\e811';
}
/* '' */
.icon-dots:before {
  content: '\e812';
}
/* '' */
.icon-question:before {
  content: '\e813';
}
/* '' */
.icon-print:before {
  content: '\e814';
}
/* '' */
.icon-pen:before {
  content: '\e815';
}
/* '' */
.icon-user:before {
  content: '\e816';
}
/* '' */
.icon-pictures:before {
  content: '\e817';
}
/* '' */
.icon-play:before {
  content: '\e818';
}
/* '' */
.icon-diagramm:before {
  content: '\e819';
}
/* '' */
.icon-nav:before {
  content: '\e81a';
}
/* '' */
.icon-empty:before {
  content: '\e81b';
}
/* '' */
.icon-phone:before {
  content: '\e81c';
}
/* '' */
.icon-email:before {
  content: '\e81d';
}
/* '' */
.icon-trash:before {
  content: '\e81e';
}
/* '' */
.icon-search-2:before {
  content: '\e81f';
}
/* '' */
.icon-tool:before {
  content: '\e820';
}
/* '' */
.icon-folder:before {
  content: '\e821';
}
/* '' */
.icon-info:before {
  content: '\e822';
}
/* '' */
.icon-home:before {
  content: '\e823';
}
/* '' */
.icon-arrow-right:before {
  content: '\e824';
}
/* '' */
.icon-arrow-left:before {
  content: '\e825';
}
/* '' */
.icon-up-left:before {
  content: '\e826';
}
/* '' */
.icon-up-right:before {
  content: '\e827';
}
/* '' */
.icon-home-star:before {
  content: '\e828';
}
/* '' */
.icon-download-alt:before {
  content: '\e829';
}
/* '' */
.icon-share:before {
  content: '\e82a';
}
/* '' */
.icon-mail-full:before {
  content: '\e82b';
}
/* '' */
.icon-pinterest:before {
  content: '\e82c';
}
/* '' */
.icon-check-thin:before {
  content: '\e82d';
}
/* '' */
.icon-lock:before {
  content: '\e82e';
}
/* '' */
.icon-lock-open:before {
  content: '\e82f';
}
/* '' */
.icon-eye:before {
  content: '\e830';
}
/* '' */
.icon-eye-off:before {
  content: '\e831';
}
/* '' */
.icon-newspaper:before {
  content: '\e832';
}
/* '' */
.icon-floppy:before {
  content: '\e833';
}
/* '' */
.icon-filter:before {
  content: '\e834';
}
/* '' */
.icon-sort-name-up:before {
  content: '\e835';
}
/* '' */
.icon-sort-name-down:before {
  content: '\e836';
}
/* '' */
.icon-eraser:before {
  content: '\e837';
}
/* '' */
.icon-leaf:before {
  content: '\e838';
}
/* '' */
.icon-file:before {
  content: '\e839';
}
/* '' */
.icon-cart:before {
  content: '\e83a';
}
/* '' */
.icon-credit-card:before {
  content: '\e83b';
}
/* '' */
.icon-requested:before {
  content: '\e83c';
}
/* '' */
.icon-purchased:before {
  content: '\e83d';
}
/* '' */
.icon-down-big:before {
  content: '\e83e';
}
/* '' */
.icon-up-big:before {
  content: '\e83f';
}
/* '' */
.icon-angle-down:before {
  content: '\e840';
}
/* '' */
.icon-basket:before {
  content: '\e841';
}
/* '' */
.icon-album:before {
  content: '\e842';
}
/* '' */
.icon-date:before {
  content: '\e843';
}
/* '' */
.icon-people:before {
  content: '\e844';
}
/* '' */
.icon-zoom-in:before {
  content: '\e845';
}
/* '' */
.icon-zoom-out:before {
  content: '\e846';
}
/* '' */
.icon-a:before {
  content: '\e849';
}
/* '' */
.icon-sw:before {
  content: '\e84a';
}
/* '' */
.icon-download:before {
  content: '\e84b';
}
/* '' */
.icon-share-1:before {
  content: '\e84c';
}
/* '' */
.icon-spin4:before {
  content: '\e84d';
}
/* '' */
.icon-spin6:before {
  content: '\e84e';
}
/* '' */
.icon-crop:before {
  content: '\e84f';
}
/* '' */
.icon-full:before {
  content: '\e850';
}
/* '' */
.icon-warning-empty:before {
  content: '\e851';
}
/* '' */
.icon-warning:before {
  content: '\e852';
}
/* '' */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/*draft styles. reorganize after approval*/
.search-form {
  width: 100%;
  height: 40px;
  position: relative;
  border: 1px solid #e5e5e5;
  float: left;
}
.search-form.homepage {
  float: none;
  width: 70%;
  margin: 0 auto;
  margin-bottom: 50px;
}
.search-form.homepage .search-input {
  width: calc(100% - 200px);
}
.search-form:before {
  display: block;
  position: absolute;
  right: 12px;
  top: 10px;
  font-size: 15px;
  color: #1f262d;
  content: '\e80a';
  font-family: 'drewaltizer', sans-serif;
  z-index: 10;
}
.search-form .search-input {
  width: 320px;
  height: 38px;
  font-size: 14px;
  font-size: 0.875rem;
  padding-left: 10px;
  font-family: Helvetica Neue, helvetica, sans-serif;
  position: absolute;
  left: 0;
  top: 0;
  border: none;
}
.search-form .search-input:-moz-placeholder {
  color: #1f262d;
}
.search-form .search-input:-ms-input-placeholder {
  color: #1f262d;
}
.search-form .search-input::-webkit-input-placeholder {
  color: #1f262d;
}
.search-form .search-input:focus {
  outline: none;
}
@media screen and (max-width: 801px), screen and (max-height: 801px) and (max-width: 1024px) {
  .search-form .search-input {
    width: 100%;
    padding-right: calc(35% + 45px);
  }
}
.search-form .choose-search {
  width: 130px;
  height: 38px;
  position: absolute;
  right: 40px;
  top: 0;
  border-right: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
  line-height: 38px;
  max-width: 130px;
  min-width: 130px;
}
.search-form .choose-search a {
  width: 130px;
  height: 38px;
  font-size: 14px;
  font-size: 0.875rem;
  color: #000000;
  display: block;
  padding: 0 10px;
  text-transform: capitalize;
}
.search-form .choose-search .icon {
  color: #c3c3c3;
  margin-right: 16px;
  font-size: 15px;
  line-height: 38px;
  vertical-align: middle;
  position: absolute;
  right: 0;
}
@media screen and (max-width: 801px), screen and (max-height: 801px) and (max-width: 1024px) {
  .search-form .choose-search {
    width: 35%;
    display: inline;
  }
}
.search-form .search-button {
  width: 60px;
  height: 60px;
  position: absolute;
  right: -20px;
  top: -10px;
  cursor: pointer;
  display: block;
  background: transparent;
  opacity: 0;
  z-index: 20;
}
.search-form .category-list {
  width: 100%;
  padding: 10px;
  background: #ffffff;
  top: -10px;
  left: 0;
  position: absolute;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
  display: none;
  z-index: 2;
}
.search-form .category-list.open {
  display: block;
}
.search-form .category-list a {
  display: block;
  color: #000000;
}
@media screen and (max-width: 801px), screen and (max-height: 801px) and (max-width: 1024px) {
  .search-form {
    margin-bottom: 30px;
    float: none;
  }
}
.search-results .events-list > li > a > img {
  height: 267px;
  display: block;
  position: relative;
  margin: 0 auto;
  left: 50%;
  -webkit-transform: translateX(-50%) translateZ(0);
  transform: translateX(-50%) translateZ(0);
}
@media screen and (max-width: 801px), screen and (max-height: 801px) and (max-width: 1024px) {
  .search-results {
    padding: 0 20px;
  }
  .search-results .events-list {
    width: 100%;
    padding: 0;
  }
  .search-results .events-list li {
    width: 100%;
  }
  .search-results .events-list .numbers-list li {
    width: 50px;
  }
}
@media screen and (max-width: 801px), screen and (max-height: 801px) and (max-width: 1024px) {
  nav.navigation.mobile-nav {
    transition: all 0.2s ease-out 0s;
    width: calc(100% - 56px);
    max-width: 320px;
    left: -100%;
    position: fixed;
    top: 68px;
    height: calc(100% - 68px);
    background: #fff;
    padding-top: 20px;
    box-shadow: 2px 0 2px rgba(0, 0, 0, 0.3);
    overflow-y: auto;
    will-change: left;
  }
  nav.navigation.mobile-nav a {
    display: block;
    font-size: 16px;
    height: 55px;
    line-height: 55px;
    text-align: left;
    color: #1f262d;
    font-family: Oswald, 'bebas-neue', 'Bebas', sans-serif;
    padding-left: 20px;
  }
  nav.navigation.mobile-nav a.w-border {
    border-bottom: 1px solid #efefef;
  }
  nav.navigation.mobile-nav.show {
    transition: all 0.2s ease-out 0.1s;
    left: 0;
  }
  .events-header .filters-part {
    position: fixed;
    right: 65px;
    top: 60px;
    background: #fff;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
    z-index: 400;
    overflow: hidden;
    display: none;
    width: 250px;
    overflow-y: scroll;
    max-height: calc(100% - 60px);
  }
  .events-header .filters-part > div {
    float: none;
    width: 100%;
    padding: 0;
    border-bottom: 1px solid #efefef;
    text-align: left;
    position: relative;
    padding: 20px;
  }
  .events-header .filters-part > div:last-child {
    border-bottom: 0;
  }
  .events-header .filters-part > div span {
    display: inline;
    text-align: left;
    max-width: 100%;
  }
  .events-header .filters-part .filter-block .filter-options {
    position: static;
    box-shadow: none;
    width: 100%;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  .events-header .filters-part .filter-block .filter-options .close-select {
    display: none;
  }
  .events-header .filters-part .filter-block .filter-options.events-date input {
    position: absolute;
    z-index: -1;
    top: -100vh;
  }
  .events-header .filters-part .pika-single.is-bound {
    display: block;
    position: relative;
    width: calc(100% + 40px) !important;
    box-shadow: none!important;
    margin: 0 -20px;
    border: none;
    padding-top: 0;
  }
  .events-header .close-select {
    display: none;
  }
  .events-header .filter-block.active .filter-link:after {
    display: none;
  }
  #fade-m {
    transition: background 0.2s ease-out 0s;
    position: fixed;
    top: 68px;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    z-index: 50;
  }
  .hide-on-mobile {
    display: none;
  }
  .sort-link {
    top: 8px;
    right: 56px;
    position: fixed;
    font-size: 22px;
    z-index: 301;
    padding: 14px;
    color: #fff !important;
    transition: all 0.3s ease-out;
  }
  .search-list .image:hover {
    z-index: 10;
  }
}
*,
*:after,
*:before {
  box-sizing: border-box;
}
html {
  font-size: 100%;
  min-height: 100%;
}
@media screen and (max-width: 801px), screen and (max-height: 801px) and (max-width: 1024px) {
  html {
    overflow-x: hidden;
  }
}
@media screen and (min-width: 801px) {
  html {
    min-width: 1024px;
  }
}
body {
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 18px;
  line-height: 1.125rem;
  font-family: Helvetica Neue, helvetica, sans-serif;
  color: #656565;
  height: 100%;
  min-height: 100%;
  overflow-x: hidden;
}
body.wide-body {
  min-width: 1240px;
}
@media screen and (max-width: 801px), screen and (max-height: 801px) and (max-width: 1024px) {
  body {
    height: auto;
    min-height: auto;
  }
  body.wide-body {
    min-width: 0;
  }
}
@media screen and (max-width: 1239px) and (min-width: 735px) {
  body {
    overflow-x: auto;
  }
}
body.menu-open main,
body.menu-open #footer {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  pointer-events: none;
}
body.sticky-footer {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  min-height: 100vh;
  position: relative;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
body.sticky-footer > .wrapper,
body.sticky-footer > .prefooter,
body.sticky-footer > footer {
  -webkit-box-flex: 1 0 auto;
  -moz-box-flex: 1 0 auto;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}
body.sticky-footer #footer {
  max-height: 108px;
}
@media screen and (max-width: 801px), screen and (max-height: 801px) and (max-width: 1024px) {
  body.sticky-footer #footer {
    max-height: 280px;
  }
}
body.with-banner .top-banner {
  display: block;
  padding: 10px 50px;
  width: 100%;
  top: 0;
  z-index: 100000;
  height: auto;
  position: fixed;
  background: #e07251;
  color: #fff;
}
body.with-banner .top-banner p {
  margin: 0;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
}
body.with-banner .navigation.mobile-nav {
  padding-top: 60px;
}
.hide {
  display: none;
}
.left-part {
  float: left;
}
.right-part {
  float: right;
}
.container {
  *zoom: 1;
  width: 1000px;
  margin: 0 auto;
}
.container:before,
.container:after {
  display: table;
  content: "";
  line-height: 0;
}
.container:after {
  clear: both;
}
.container:before,
.container:after {
  display: table;
  content: "";
  line-height: 0;
}
.container:after {
  clear: both;
}
@media screen and (max-width: 801px), screen and (max-height: 801px) and (max-width: 1024px) {
  .container {
    width: 100%;
    padding: 0 20px;
  }
}
.row {
  *zoom: 1;
}
.row:before,
.row:after {
  display: table;
  content: "";
  line-height: 0;
}
.row:after {
  clear: both;
}
.row:before,
.row:after {
  display: table;
  content: "";
  line-height: 0;
}
.row:after {
  clear: both;
}
.col-3 {
  width: 310px;
  margin-right: 35px;
  float: left;
}
.col-3:nth-child(3n) {
  margin-right: 0;
}
.top-banner {
  display: none;
  height: 0;
}
.wrapper {
  min-height: 100%;
  padding-bottom: 108px;
}
@media screen and (max-width: 801px), screen and (max-height: 801px) and (max-width: 1024px) {
  .wrapper {
    padding-bottom: 0;
  }
}
.inner-page #header {
  height: 121px;
  border-bottom: 1px solid #e5e5e5;
}
.inner-page .wrapper {
  padding-top: 121px;
}
.inner-page main {
  padding-top: 30px;
  display: block;
}
.inner-page .prefooter {
  background: #ffffff;
}
@media screen and (max-width: 801px), screen and (max-height: 801px) and (max-width: 1024px) {
  .inner-page #header {
    height: 68px;
    border-bottom: 0;
  }
  .inner-page .wrapper {
    padding-top: 78px;
  }
}
#header {
  height: 121px;
  transition: all 0.3s ease-out;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  padding: 25px 0 10px;
  top: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 1000;
  position: fixed;
}
@media screen and (min-width: 921px) and (max-width: 1020px) {
  #header .container {
    zoom: 0.9;
    -moz-transform: scale(0.9);
    -moz-transform-origin: 40% 0;
  }
}
@media screen and (min-width: 921px) and (max-width: 950px) {
  #header .container {
    -moz-transform-origin: 15% 0;
  }
}
@media screen and (min-width: 821px) and (max-width: 920px) {
  #header .container {
    zoom: 0.8;
    -moz-transform: scale(0.8);
    -moz-transform-origin: 20% 0;
  }
}
@media screen and (min-width: 821px) and (max-width: 850px) {
  #header .container {
    -moz-transform-origin: 10% 0;
  }
}
@media screen and (min-width: 737px) and (max-width: 820px) {
  #header .container {
    zoom: 0.73;
    -moz-transform: scale(0.73);
    -moz-transform-origin: 13% 0;
  }
}
@media screen and (min-width: 737px) and (max-width: 770px) {
  #header .container {
    -moz-transform-origin: 6% 0;
  }
}
@media screen and (min-width: 801px) {
  #header.header-transparent {
    background-color: transparent;
    border-bottom: none;
    color: #ffffff;
  }
  #header.header-transparent .logo-link {
    top: -23px;
  }
  #header.header-transparent .logo-link img {
    height: 136px;
    width: 115px;
  }
  #header.header-transparent a {
    color: #ffffff;
  }
}
#header a {
  font-size: 13px;
  font-size: 0.8125rem;
  color: #1f262d;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
}
#header a.logo-link {
  position: absolute;
  top: 0;
  left: 50%;
  transition: all 0.3s ease-out;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
#header a.logo-link img {
  width: 83px;
  height: 57px;
}
#header a:before {
  display: block;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 100%;
  left: 0;
}
#header a.logo-link:before {
  height: 23px;
}
#header a:after {
  width: 0px;
  height: 4px;
  background: #f3b202;
  display: block;
  position: absolute;
  left: 50%;
  bottom: -10px;
  opacity: 0;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  content: "";
}
#header a.active:after,
#header a:hover:after {
  -webkit-animation: headerLinks 0.2s ease forwards;
  animation: headerLinks 0.2s ease forwards;
  opacity: 1;
}
#header a.authorized {
  margin-left: 60px;
}
#header .search-block {
  position: absolute;
  left: 0;
  top: 0;
}
#header .mail-block {
  position: absolute;
  right: 0;
  top: 0;
}
#header .container {
  position: relative;
}
#header .right-part {
  white-space: nowrap;
}
#header .right-part .hidden {
  display: none;
}
@media screen and (max-width: 801px), screen and (max-height: 801px) and (max-width: 1024px) {
  #header {
    background: #e07251;
    color: #fff;
    border-bottom: 0;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    top: 0;
    z-index: 300;
    height: 68px;
  }
  #header a:after {
    display: none;
  }
  #header a.logo-link {
    left: 0;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  #header a.logo-link img {
    width: 42px;
    height: 29px;
  }
  #header a.authorized {
    margin-left: 0;
  }
  #header .search-block {
    left: 100%;
    margin-left: -65px;
  }
  #header .mobile-menu {
    font-size: 13px;
    font-size: 0.8125rem;
    font-weight: 600;
    position: absolute;
    right: 0;
    z-index: 5;
  }
  #header .close-menu-link {
    position: absolute;
  }
  #header .search-link {
    top: 8px;
    right: 6px;
    position: absolute;
    font-size: 20px;
    color: #fff;
    padding: 14px;
  }
  #header .search-link:before {
    content: none;
  }
  #header .category-link:before {
    content: none;
  }
  #header.header-transparent .category-list a,
  #header.header-transparent .category-link a,
  #header.header-transparent .category-list span,
  #header.header-transparent .category-link span {
    color: black;
  }
  #header .search-form {
    margin-bottom: 30px;
    float: none;
    position: absolute;
    right: 20px;
    top: 0;
    width: calc(100% - 40px);
    top: 15px;
    z-index: 10;
    border: none;
    display: none;
  }
  #header .navigation.show {
    top: 68px;
  }
  #header .container {
    margin-top: -25px;
  }
  #header .container .mobile-menu {
    left: 10px;
    right: 0;
    color: #fff;
    top: 11px;
    height: 44px;
    width: 44px;
  }
  #header .container .mobile-menu .line {
    width: 25px;
    height: 3px;
    display: block;
    background: #fff;
    position: absolute;
    left: 10px;
  }
  #header .container .mobile-menu .line.line-1 {
    transition: all 0.2s ease-out 0s;
    top: 15px;
    -webkit-transform: skewX(0deg) rotate(0deg) translateY(0px) translateX(0px);
    transform: skewX(0deg) rotate(0deg) translateY(0px) translateX(0px);
  }
  #header .container .mobile-menu .line.line-2 {
    top: 22px;
  }
  #header .container .mobile-menu .line.line-3 {
    transition: all 0.2s ease-out 0s;
    top: 29px;
  }
  #header .container .mobile-menu.open .line-1 {
    width: 15px;
    transition: all 0.2s ease-out 0s;
    -webkit-transform: skewX(24deg) rotate(-29deg) translateY(2px) translateX(-4px);
    transform: skewX(24deg) rotate(-29deg) translateY(2px) translateX(-4px);
  }
  #header .container .mobile-menu.open .line-3 {
    transition: all 0.2s ease-out 0s;
    width: 15px;
    -webkit-transform: skewX(-24deg) rotate(29deg) translateY(-2px) translateX(-4px);
    transform: skewX(-24deg) rotate(29deg) translateY(-2px) translateX(-4px);
  }
  #header .container a.logo-link {
    left: 80px;
    top: 20px;
    opacity: 0;
  }
  #header .container a.logo-link.active {
    opacity: 1;
  }
}
main {
  padding: 70px 0 50px;
  display: block;
}
.hide {
  display: none;
}
.margin-top {
  margin-top: 10px;
}
.with-margin {
  margin: 0 50px 50px;
}
@media screen and (max-width: 801px), screen and (max-height: 801px) and (max-width: 1024px) {
  .with-margin {
    margin: 0 auto 50px;
  }
}
.wide-content {
  margin: 0 auto;
  width: 1240px;
}
@media screen and (max-width: 801px), screen and (max-height: 801px) and (max-width: 1024px) {
  .wide-content {
    width: 100%;
  }
  .wide-content .common-photos {
    width: 100%;
    padding: 0 20px;
  }
}
.navigation {
  *zoom: 1;
  position: relative;
}
.navigation:before,
.navigation:after {
  display: table;
  content: "";
  line-height: 0;
}
.navigation:after {
  clear: both;
}
.navigation:before,
.navigation:after {
  display: table;
  content: "";
  line-height: 0;
}
.navigation:after {
  clear: both;
}
.navigation .left-part a {
  margin-right: 40px;
}
.navigation .left-part a:last-of-type {
  margin-right: 0;
}
.navigation .left-part.no-subscribe a {
  margin-right: 121px;
}
.navigation .left-part.no-subscribe a:last-of-type {
  margin-right: 0;
}
.navigation .right-part a {
  margin-left: 74px;
}
.navigation .right-part a:first-of-type {
  margin-left: 0;
}
@media screen and (max-width: 801px), screen and (max-height: 801px) and (max-width: 1024px) {
  .navigation .left-part,
  .navigation .right-part {
    width: 100%;
    position: relative;
    top: -300px;
    background: #fff;
    display: none;
  }
  .navigation .left-part a,
  .navigation .right-part a {
    display: block;
    margin: 0;
    height: 50px;
    line-height: 50px;
    text-align: center;
  }
  .navigation .left-part a[data-type='signup'],
  .navigation .right-part a[data-type='signup'] {
    margin-left: 0px;
  }
  .navigation .left-part a.close-menu-link,
  .navigation .right-part a.close-menu-link {
    width: 50px;
    height: 30px;
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 20px;
  }
  .navigation.show {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 200;
    position: fixed;
    padding: 0;
    margin: 0;
    display: block;
  }
  .navigation.show .left-part,
  .navigation.show .right-part {
    display: block;
    float: none;
    position: static;
  }
  .navigation.show .logo-link {
    display: none;
  }
  .navigation.show .close-menu-link {
    z-index: 200;
  }
  .navigation.show .right-part {
    border-bottom: 1px solid #e5e5e5;
  }
}
#footer {
  font-size: 10px;
  font-size: 0.625rem;
  background: #1f262d;
  height: 108px;
  color: #edeceb;
  font-family: Oswald, 'bebas-neue', 'Bebas', sans-serif;
  position: relative;
  margin-top: -108px;
}
#footer .copyright {
  float: left;
  margin-top: 20px;
  letter-spacing: 1px;
}
#footer .social-block {
  font-size: 20px;
  float: right;
  line-height: 105px;
}
#footer .social-block a {
  color: #edeceb;
  margin-left: 20px;
}
#footer .social-block a:hover {
  color: #e38063;
}
#footer .book-us {
  width: 200px;
  height: 108px;
  color: #ffffff;
  background: #e07251;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  text-align: center;
  padding: 15px 0;
  text-transform: uppercase;
}
#footer .book-us .large {
  font-size: 33px;
  font-size: 2.0625rem;
  line-height: 33px;
  line-height: 2.0625rem;
  display: block;
}
#footer .book-us .small {
  font-size: 10px;
  font-size: 0.625rem;
  line-height: 10px;
  line-height: 0.625rem;
  display: block;
  letter-spacing: 1px;
}
#footer .book-us .icon {
  font-size: 6px;
  line-height: 2px;
  display: block;
  color: #f3b202;
  margin: 18px 0 15px;
  letter-spacing: 4px;
}
#footer .book-us:before {
  display: block;
  content: "";
  border-top: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 40px solid #e07251;
  position: absolute;
  top: -10px;
  left: 0;
}
#footer .book-us:hover {
  background: #e38063;
}
#footer .book-us:hover:before {
  border-left-color: #e38063;
  transition: all 0.2s ease-out 0s;
}
@media screen and (max-width: 801px), screen and (max-height: 801px) and (max-width: 1024px) {
  #footer {
    height: 280px;
    padding-top: 110px;
    margin-top: 50px;
  }
  #footer .built {
    margin-top: 20px;
    display: block;
  }
  #footer .book-us {
    width: 100%;
  }
  #footer .copyright {
    font-size: 10px;
    font-size: 0.625rem;
    line-height: 10px;
    line-height: 0.625rem;
    width: 100%;
    float: none;
    text-align: center;
    height: auto;
    margin-bottom: 30px;
    margin-top: 30px;
  }
  #footer .copyright .privacy {
    top: 10px;
    position: relative;
  }
  #footer .copyright .sitemap {
    position: relative;
    top: 15px;
  }
  #footer .social-block {
    height: auto;
    font-size: 25px;
    line-height: 20px;
    float: none;
    width: 100%;
    text-align: center;
  }
  #footer .social-block a {
    padding: 10px;
    margin: 0 5px;
  }
}
.show-on-mobile {
  display: none;
}
@media screen and (max-width: 801px), screen and (max-height: 801px) and (max-width: 1024px) {
  .show-on-mobile {
    display: block;
  }
}
.hide-on-mobile {
  display: block;
}
@media screen and (max-width: 801px), screen and (max-height: 801px) and (max-width: 1024px) {
  .hide-on-mobile {
    display: none !important;
  }
}
.hide-on-mobile-inline {
  display: inline;
}
@media screen and (max-width: 801px), screen and (max-height: 801px) and (max-width: 1024px) {
  .hide-on-mobile-inline {
    display: none !important;
  }
}
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0.5em;
  box-sizing: border-box;
  z-index: 100000;
}
.modal-background .modal-outer {
  position: relative;
  width: 100%;
  height: 100%;
}
.modal-background .modal-outer .modal {
  position: absolute;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  display: block;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
  padding: 30px;
}
::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
::-webkit-calendar-picker-indicator {
  padding: 15px;
}
@-webkit-keyframes headerLinks {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes headerLinks {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
.subscribe-newsletter {
  margin-bottom: 25px;
}
.subscribe-newsletter input {
  -webkit-appearance: checkbox;
  margin: 0;
  position: relative;
  top: 2px;
}
@media screen and (max-width: 801px), screen and (max-height: 801px) and (max-width: 1024px) {
  body.sticky-footer.large-footer #footer {
    margin-top: 0;
    padding-top: 108px;
    height: 315px;
    max-height: 320px;
  }
}
.scroller.mobile-hide {
  position: fixed;
  bottom: 120px;
  right: 50px;
  cursor: pointer;
  opacity: 0.7;
  background: #EAEAEA;
  border-radius: 30px;
  padding: 10px 13px;
  z-index: 2;
  display: none;
  transition: all 0.3s ease;
}
.scroller.mobile-hide:hover {
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.4);
}
.swipe-area {
  position: fixed;
  width: 10%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 100;
}
body.with-overlay {
  overflow: hidden;
}
body.with-overlay .instructional-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9999;
}
body .instructional-box {
  font-family: Oswald, 'bebas-neue', 'Bebas', sans-serif;
  font-weight: 100;
  position: fixed;
  font-size: 16px;
  z-index: 10000;
  color: #ffffff;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
body .instructional-box img {
  border: 2px solid #e07251;
}
body .filters-instructions {
  width: 270px;
}
body .filters-instructions img {
  display: block;
  margin-bottom: 10px ;
}
body .social-instructions img {
  float: left;
}
body .social-instructions p {
  float: left;
  margin-left: 20px;
  width: 250px;
}
body .tabs-instructions img {
  display: block;
}
body .tabs-instructions p {
  margin-bottom: 10px;
}
body .sizes-instructions p {
  position: absolute;
  right: 100%;
  width: 250px;
  top: 0;
}
body .material-instructions img {
  float: left;
}
body .material-instructions p {
  margin: -8px 0 0 10px;
  float: right;
}
body .close-btn {
  font-size: 16px;
  color: #ffffff;
  position: absolute;
  right: 20px;
  top: 20px;
}
body .close-btn:hover {
  text-shadow: 0 0 15px #ffffff, 0 0 10px #ffffff, 0 0 5px #ffffff;
}
@media screen and (max-width: 801px), screen and (max-height: 801px) and (max-width: 1024px) {
  body .instructional-box {
    font-size: 14px;
  }
  body .photo-instructions {
    position: static;
    color: #1f262d;
    margin-top: 30px;
  }
  body .photo-instructions p {
    margin-bottom: 5px;
  }
  body .filters-instructions {
    top: 80px;
    left: 5%;
    width: 80%;
  }
  body .filters-instructions img {
    display: none;
  }
  body .filters-icon {
    border: 1px solid #ffffff;
    font-size: 22px;
    color: #ffffff;
    position: fixed;
    width: 68px;
    height: 68px;
    text-align: center;
    line-height: 60px;
    top: 0;
    right: 45px;
    background: #e07251;
  }
}
@media screen and (max-width: 801px), screen and (max-height: 801px) and (max-width: 1024px) {
  body.book-us-page #footer {
    padding-top: 0;
    max-height: 170px;
  }
  body.book-us-page #footer .book-us {
    display: none;
  }
}
i,
em {
  font-style: italic;
}
b,
strong {
  font-weight: bold;
}
blockquote {
  text-align: cetner;
  font-family: 'Garamond', 'Times New Roman', serif;
}
a {
  text-decoration: none;
  color: #e07251;
}
a:hover {
  transition: all 0.2s ease-out 0s;
  color: #e38063;
}
a:visited {
  color: #c96649;
}
a:focus {
  outline: none;
}
.text-center {
  text-align: center;
}
h1 {
  font-size: 50px;
  font-size: 3.125rem;
  line-height: 50px;
  line-height: 3.125rem;
  font-family: Oswald, 'bebas-neue', 'Bebas', sans-serif;
  color: #1f262d;
  margin-bottom: 30px;
  /*check it*/
  word-spacing: 4px;
}
@media screen and (max-width: 801px), screen and (max-height: 801px) and (max-width: 1024px) {
  h1.smaller-on-mobile {
    font-size: 26px;
    font-size: 1.625rem;
    line-height: 26px;
    line-height: 1.625rem;
  }
}
h2 {
  color: #1f262d;
}
h2.page-title {
  font-size: 22px;
  font-size: 1.375rem;
  letter-spacing: 1px;
  margin-bottom: 50px;
}
h2.page-title span {
  position: relative;
  display: inline-block;
  font-family: Oswald, 'bebas-neue', 'Bebas', sans-serif;
}
h2.page-title span:after {
  width: 100%;
  height: 1px;
  display: block;
  background: #e5e5e5;
  position: absolute;
  left: 0;
  bottom: -25px;
  content: "";
}
@media screen and (max-width: 801px), screen and (max-height: 801px) and (max-width: 1024px) {
  h2.page-title {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
h3 {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 22px;
  line-height: 1.375rem;
}
.all-link {
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: bold;
  text-transform: uppercase;
}
.breadcrumbs {
  max-width: 100%;
  height: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.breadcrumbs li {
  display: inline-block;
  color: #e07251;
}
.breadcrumbs li a {
  padding-right: 10px;
  position: relative;
  font-family: 'Helvetica';
  max-width: 130px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #1f262d;
}
.breadcrumbs li a.breadcrumb-url {
  color: #e07251;
}
.breadcrumbs li a:after {
  content: '\203a';
  position: absolute;
  right: 3px;
  top: 0;
  display: block;
  color: #000000;
}
.breadcrumbs li:last-of-type a {
  padding-right: 0;
}
.breadcrumbs li:last-of-type a:after {
  display: none;
}
@media screen and (max-width: 801px), screen and (max-height: 801px) and (max-width: 1024px) {
  .breadcrumbs {
    display: none;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html {
    position: relative;
    min-height: 100%;
  }
  body.sticky-footer {
    min-height: auto;
    position: static;
  }
  .account-page .form-group select[name=sortBy] {
    padding-right: 0;
  }
  #footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }
}
@media screen and (max-width: 801px), screen and (max-height: 801px) and (max-width: 1024px) and (-ms-high-contrast: active), screen and (max-width: 801px), screen and (max-height: 801px) and (max-width: 1024px) and (-ms-high-contrast: none) {
  .wrapper .photo-row .info-block .tabs-block .tab-head {
    display: inline-block;
    vertical-align: bottom;
    background: none;
  }
  #footer {
    position: relative;
  }
}
