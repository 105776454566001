@import (reference) 'variables';

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html {
    position: relative;
    min-height: 100%;
  }

  body {
    &.sticky-footer {
      min-height: auto;
      position: static;
    }
  }

  .account-page {
    .form-group {
      select[name=sortBy] {
        padding-right: 0;
      }
    }
  }

  #footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }
}

@media @mobile and (-ms-high-contrast: active), @mobile and (-ms-high-contrast: none) {
  .wrapper {
    .photo-row .info-block .tabs-block .tab-head {
      display: inline-block;
      vertical-align: bottom;
      background: none;
    }
  }

  #footer {
    position: relative;
  }
}