@import (reference) "variables";
@import (reference) "mixins";
@import "fonts";

.search-form {
  .size(100%,40px);

  position: relative;
  border: 1px solid @color-title-border;
  float: left;
  &.homepage {
    float: none;
    width: 70%;
    margin: 0 auto;
    margin-bottom: 50px;
    .search-input {
      width: ~"calc(100% - 200px)";
    }
  }
  &:before {
    display: block;
    position: absolute;
    right: 12px;
    top: 10px;
    font-size: 15px;
    color: @color-dark;
    content: '\e80a';
    font-family: @font-icon;
    z-index: 10;
  }

  .search-input {
    .size(320px,38px);
    .placeholder(@color-dark);
    .font-size(14);

    padding-left: 10px;
    font-family: @font-base;
    position: absolute;
    left: 0;
    top: 0;
    border: none;

    &:focus {
      outline: none;
    }

    @media @mobile {
      width: 100%;
      padding-right: ~'calc(35% + 45px)';
    }

  }
  .choose-search {
    .size(130px,38px);

    position: absolute;
    right: 40px;
    top: 0;
    border-right: 1px solid @color-title-border;
    border-left: 1px solid @color-title-border;
    line-height: 38px;
    max-width: 130px;
    min-width: 130px;

    a {
      .size(130px,38px);
      .font-size(14);

      color: @black;
      display: block;
      padding: 0 10px;
      text-transform: capitalize;
    }

    .icon {
      color: #c3c3c3;
      margin-right: 16px;
      font-size: 15px;
      line-height: 38px;
      vertical-align: middle;
      position: absolute;
      right: 0;
    }

    @media @mobile {
      width: 35%;
      display: inline;
    }
  }

  .search-button {
    .size(60px,60px);

    position: absolute;
    right: -20px;
    top: -10px;
    cursor: pointer;
    display: block;
    background: transparent;
    opacity: 0;
    z-index: 20;
  }

  .category-list {
    width: 100%;
    padding: 10px;
    background: @white;
    top: -10px;
    left: 0;
    position: absolute;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
    display: none;
    z-index: 2;

    &.open {
      display: block;
    }

    a {
      display: block;
      color: @black;
    }
  }

  @media @mobile {
    margin-bottom: 30px;
    float: none;
  }
}

.search-results {
  .events-list {
    >li {
      > a {
        > img {
          height: 267px;
          display: block;
          position: relative;
          margin: 0 auto;
          left: 50%;
          -webkit-transform: translateX(-50%) translateZ(0);
          transform: translateX(-50%)  translateZ(0);
        }
      }
    }
  }

  @media @mobile {
    padding: 0 20px;

    .events-list {
      width: 100%;
      padding: 0;

       li{
         width: 100%;
       }

      .numbers-list {
        li {
          width: 50px;
        }
      }
    }
  }
}