@import (reference) "variables";
@import (reference) "mixins";

@import "md";

*,
*:after,
*:before {
  box-sizing: border-box;
}

html {
  font-size: 100%;
  min-height: 100%;

  @media @mobile {
    overflow-x: hidden;
  }

  @media @desktop {
    min-width: 1024px;
  }
}

body {
  .font-size(13);
  .line-height(18);

  font-family: @font-base;
  color: @color-text;
  height: 100%;
  min-height: 100%;
  overflow-x: hidden;


  &.wide-body {
    min-width: 1240px;
  }

  @media @mobile {
    height: auto;
    min-height: auto;

    &.wide-body {
      min-width: 0;
    }
  }


  @media  screen and (max-width: 1239px) and (min-width: 735px){
    overflow-x: auto;
  }

  &.menu-open {
    main,
    #footer {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      pointer-events: none;
    }
  }

  &.sticky-footer {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    min-height: 100vh;
    position: relative;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;

    >.wrapper,
    >.prefooter,
    >footer {
      -webkit-box-flex: 1 0 auto;
      -moz-box-flex: 1 0 auto;
      -webkit-flex: 1 0 auto;
      -ms-flex: 1 0 auto;
      flex: 1 0 auto;
    }

    #footer{
      max-height: 108px;
    }

    @media @mobile {
      //display: block;
      //min-height:0;

      #footer {
        max-height: 280px;
      }
    }
  }

  &.with-banner {
    .top-banner {
      display: block;
      padding: 10px 50px;
      width: 100%;
      top: 0;
      z-index: 100000;
      height: auto;
      position:fixed;
      background: #e07251;
      color: #fff;

      p {
        margin: 0;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
      }
    }

    .navigation.mobile-nav {
      padding-top: 60px;
    }
  }
}

.hide {
  display: none;
}

.left-part {
  float: left;
}

.right-part {
  float: right;
}

.container {
  .clearfix();

  width: 1000px;
  margin: 0 auto;


  @media @mobile {
    width: 100%;
    padding: 0 20px;

  }
}

.row {
  .clearfix();
}

.col-3 {
  width: 310px;
  margin-right: 35px;
  float: left;

  &:nth-child(3n) {
    margin-right: 0;
  }
}

.top-banner {
  display: none;
  height: 0;
}

.wrapper {
  min-height: 100%;
  padding-bottom: @footer-height;

  @media @mobile {
    padding-bottom: 0;
  }
}

.inner-page {
  #header {
    height: 121px;
    border-bottom: 1px solid #e5e5e5;
  }

  .wrapper {
    padding-top: @header-height;
  }

  main {
    padding-top: 30px;
    display: block;
  }

  .prefooter {
    background: @white;
  }

  @media @mobile {
    #header {
      height: 68px;
      border-bottom: 0;
    }

    .wrapper {
      padding-top: 78px;
    }
  }
}

#header {
  height: @header-height;
  transition: all 0.3s ease-out;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
  padding: 25px 0 10px;
  top: 0;
  width: 100%;
  background-color: @white;
  z-index: 1000;
  position: fixed;

  .container {
    @media screen and (min-width: 921px) and (max-width: 1020px) {
      zoom: 0.9;
      -moz-transform: scale(0.9);
      -moz-transform-origin: 40% 0;
    }
    @media screen and (min-width: 921px) and (max-width: 950px) {
      -moz-transform-origin: 15% 0;
    }
    @media screen and (min-width: 821px) and (max-width: 920px) {
      zoom: 0.8;
      -moz-transform: scale(0.8);
      -moz-transform-origin: 20% 0;
    }
    @media screen and (min-width: 821px) and (max-width: 850px) {
      -moz-transform-origin: 10% 0;
    }
    @media screen and (min-width: 737px) and (max-width: 820px) {
      zoom: 0.73;
      -moz-transform: scale(0.73);
      -moz-transform-origin: 13% 0;
    }
    @media screen and (min-width: 737px) and (max-width: 770px) {
      -moz-transform-origin: 6% 0;
    }
  }

  @media @desktop {
    &.header-transparent {
      background-color: transparent;
      border-bottom: none;
      color: @white;

      .logo-link {
        top: -23px;

        img {
          height: 136px;
          width: 115px;
        }
      }

      a {
        color: @white;
      }
    }
  }

  a {
    .font-size(13);

    color: @color-dark;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;

    &.logo-link {
      position: absolute;
      top: 0;
      left: 50%;
      transition: all 0.3s ease-out;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);

      img {
        width: 83px;
        height: 57px;
      }
    }

    &:before {
      display: block;
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      top: 100%;
      left: 0;
    }

    &.logo-link:before {
      height: 23px;
    }

    &:after {
      .size(0px, 4px);

      background: @color-yellow;
      display: block;
      position: absolute;
      left: 50%;
      bottom: -10px;
      opacity: 0;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      content: "";
    }

    &.active:after,
    &:hover:after{
      -webkit-animation: headerLinks .2s ease forwards;
      animation: headerLinks .2s ease forwards;
      opacity: 1;
    }

    &.authorized {
      margin-left: 60px;
    }
  }

  .search-block {
    position: absolute;
    left: 0;
    top: 0;
  }
  .mail-block {
    position: absolute;
    right: 0;
    top: 0;
  }

  .container {
    position: relative;
  }

  .right-part {
    white-space: nowrap;
    .hidden{
      display: none;
    }
  }

  @media @mobile {
    background: #e07251;
    color: #fff;
    border-bottom: 0;
    box-shadow: 0 2px 2px rgba(0,0,0,.2);
    top: 0;
    z-index: 300;
    height: 68px;

    a {
      &:after {
        display: none;
      }

      &.logo-link {
        left: 0;
        -webkit-transform: translateX(0);
        transform: translateX(0);

        img{
          .size(42px,29px);
        }
      }

      &.authorized {
        margin-left: 0;
      }
    }

    .search-block {
      left: 100%;
      margin-left: -65px;
    }

    .mobile-menu {
      .font-size(13);

      font-weight: 600;
      position: absolute;
      right: 0;
      z-index: 5;

    }

    .close-menu-link {
      position: absolute;
    }
    .search-link {
      top: 8px;
      right: 6px;
      position: absolute;
      font-size: 20px;
      color: #fff;
      padding: 14px;

      &:before{
        //to prevent weird click behaviour nearby
        content: none;
      }
    }
    .category-link{
      &:before{
        //to prevent weird click behaviour nearby
        content: none;
      }
    }

    &.header-transparent{
      .category-list, .category-link {
        a, span {
          color: black;
        }
      }
    }

    .search-form {
      margin-bottom: 30px;
      float: none;
      position: absolute;
      right: 20px;
      top: 0;
      width: ~"calc(100% - 40px)";
      top: 15px;
      z-index: 10;
      border: none;
      display: none;
    }



    .navigation.show{
      top: 68px;
    }

    .container {
      margin-top: -25px;

      .mobile-menu {
        left: 10px;
        right: 0;
        color: #fff;
        top: 11px;
        height: 44px;
        width: 44px;


        .line {
          width: 25px;
          height: 3px;
          display: block;
          background: #fff;
          position: absolute;
          left: 10px;

          &.line-1 {
            transition: all .2s ease-out 0s;
            top: 15px;
            -webkit-transform: skewX(0deg) rotate(0deg) translateY(0px) translateX(0px);
            transform: skewX(0deg) rotate(0deg) translateY(0px) translateX(0px);
          }

          &.line-2 {
            top: 22px;
          }

          &.line-3 {
            transition: all .2s ease-out 0s;
            top: 29px;
          }
        }

        &.open {
          .line-1 {
            width: 15px;
            transition: all .2s ease-out 0s;
            -webkit-transform: skewX(24deg) rotate(-29deg) translateY(2px) translateX(-4px);
            transform: skewX(24deg) rotate(-29deg) translateY(2px) translateX(-4px);
          }

          .line-3 {
            transition: all .2s ease-out 0s;
            width: 15px;
            -webkit-transform: skewX(-24deg) rotate(29deg) translateY(-2px) translateX(-4px);
            transform: skewX(-24deg) rotate(29deg) translateY(-2px) translateX(-4px);
          }
        }
      }

      a.logo-link {
        left: 80px;
        top: 20px;
        opacity: 0;

        &.active {
          opacity: 1;
        }
      }
    }
  }
}

main {
  padding: 70px 0 50px;
  display: block;
}

.hide {
  display: none;
}

.margin-top {
  margin-top: 10px
}

.with-margin {
  margin: 0 50px 50px;

  @media @mobile {
    margin: 0 auto 50px;
  }
}

.wide-content {
  margin: 0 auto;
  width: 1240px;

  @media @mobile {
    width: 100%;

    .common-photos {
      width: 100%;
      padding: 0 20px;
    }
  }
}

.navigation {
  .clearfix();

  position: relative;

  .left-part {
    a {
      margin-right: 40px;

      &:last-of-type {
        margin-right: 0;
      }
    }

    &.no-subscribe {
      a {
        margin-right: 121px;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  .right-part {
    a {
      margin-left: 74px;

      &:first-of-type {
        margin-left: 0;
      }
    }
  }

  @media @mobile {

    .left-part, .right-part {
      width: 100%;
      position: relative;
      top: -300px;
      background: #fff;
      display: none;

      a {
        display: block;
        margin: 0;
        height: 50px;
        line-height: 50px;
        text-align: center;

        &[data-type='signup'] {
          margin-left: 0px;
        }

        &.close-menu-link {
          .size(50px, 30px);

          position: absolute;
          top: 0px;
          right: 0px;
          font-size: 20px;
        }
      }
    }

    &.show {
      .size(100%, 100%);

      top: 0;
      left: 0;
      z-index: 200;
      position: fixed;
      padding: 0;
      margin: 0;
      display: block;

      .left-part,
      .right-part {
        display: block;
        float: none;
        position: static;
      }

      .logo-link {
        display: none;
      }

      .close-menu-link {
        z-index: 200;
      }

      .right-part {
        border-bottom: 1px solid #e5e5e5;
      }
    }
  }
}

#footer {
  .font-size(10);

  background: @color-dark;
  height: @footer-height;
  color: @color-light;
  font-family: @font-bebas;
  position: relative;
  margin-top: -@footer-height;

  .copyright {
    float: left;
    margin-top: 20px;
    letter-spacing: 1px;
  }

  .social-block {
    font-size: 20px;
    float: right;
    line-height: @footer-height - 3px;

    a {
      color: @color-light;
      margin-left: 20px;

      &:hover {
        color: @color-link-hover;
      }
    }
  }

  .book-us {
    .size(200px, @footer-height);

    color: @white;
    background: @color-link;
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    text-align: center;
    padding: 15px 0;
    text-transform: uppercase;

    .large {
      .font-size(33);
      .line-height(33);

      display: block;
    }

    .small {
      .font-size(10);
      .line-height(10);

      display: block;
      letter-spacing: 1px;
    }

    .icon {
      font-size: 6px;
      line-height: 2px;
      display: block;
      color: @color-yellow;
      margin: 18px 0 15px;
      letter-spacing: 4px;
    }

    &:before {
      display: block;
      content: "";
      border-top: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid transparent;
      border-left: 40px solid @color-link;
      position: absolute;
      top: -10px;
      left: 0;
    }

    &:hover {
      background: @color-link-hover;

      &:before {
        border-left-color: @color-link-hover;
        transition: all .2s ease-out 0s;
      }
    }
  }

  @media @mobile {
    height: 280px;
    padding-top: 110px;
    margin-top: 50px;

    .built {
      margin-top: 20px;
      display: block;
    }

    .book-us {
      width: 100%;
    }

    .copyright {
      .font-size(10);
      .line-height(10);

      width: 100%;
      float: none;
      text-align: center;
      height: auto;
      margin-bottom: 30px;
      margin-top: 30px;

      .privacy {
        top: 10px;
        position: relative;
      }

      .sitemap {
        position: relative;
        top: 15px;
      }
    }
    .social-block {
      height: auto;
      font-size: 25px;
      line-height: 20px;
      float: none;
      width: 100%;
      text-align: center;

      a {
        padding: 10px;
        margin: 0 5px;
      }
    }
  }
}

.show-on-mobile {
  display: none;

  @media @mobile {
    display: block;
  }
}

.hide-on-mobile {
  display: block;

  @media @mobile {
    display: none !important;
  }
}

.hide-on-mobile-inline {
  display: inline;

  @media @mobile {
    display: none !important;
  }
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0.5em;
  box-sizing: border-box;
  z-index: 100000;

  .modal-outer {
    position: relative;
    width: 100%;
    height: 100%;

    .modal {
      position: absolute;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
      display: block;
      max-width: 100%;
      max-height: 100%;
      box-sizing: border-box;
      padding: 30px;
    }
  }
}

//date inputs
::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

::-webkit-calendar-picker-indicator {
  padding: 15px;
}

@-webkit-keyframes headerLinks {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes headerLinks {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

.subscribe-newsletter {
  margin-bottom: 25px;

  input {
    -webkit-appearance: checkbox;
    margin: 0;
    position: relative;
    top: 2px;
  }
}

body.sticky-footer.large-footer #footer {
  @media @mobile {
    margin-top: 0;
    padding-top: 108px;
    height: 315px;
    max-height: 320px;
  }
}

.scroller {
  &.mobile-hide {
    position: fixed;
    bottom: 120px;
    right: 50px;
    cursor: pointer;
    opacity: .7;
    background: #EAEAEA;
    border-radius: 30px;
    padding: 10px 13px;
    z-index: 2;
    display: none;
    transition: all 0.3s ease;
    &:hover {
      box-shadow: 0 0 9px rgba(0, 0, 0, .4);
    }
  }
}

.swipe-area {
  position: fixed;
  width: 10%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 100;
}

body {
  &.with-overlay {
    overflow: hidden;

    .instructional-overlay {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, .7);
      z-index: 9999;
    }
  }

  .instructional-box {
    font-family: @font-bebas;
    font-weight: 100;
    position: fixed;
    font-size: 16px;
    z-index: 10000;
    color: @white;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;

    img {
      border: 2px solid @color-orange;
    }
  }

  .filters-instructions {
    width: 270px;

    img {
      display: block;
      margin-bottom: 10px ;
    }
  }

  .social-instructions {
    img {
      float: left;
    }

    p {
      float: left;
      margin-left: 20px;
      width: 250px;
    }
  }

  .tabs-instructions {
    img {
      display: block;
    }

    p {
      margin-bottom: 10px;
    }
  }

  .sizes-instructions {
    p {
      position: absolute;
      right: 100%;
      width: 250px;
      top: 0;
    }
  }

  .material-instructions {
    img {
      float: left;
    }
    p {
      margin: -8px 0 0 10px;
      float: right;
    }
  }

  .close-btn {
    font-size: 16px;
    color: @white;
    position: absolute;
    right: 20px;
    top: 20px;

    &:hover {
      text-shadow: 0 0 15px @white, 0 0 10px @white, 0 0 5px @white;
    }
  }

  @media @mobile {
    .instructional-box {
      font-size: 14px;
    }

    .photo-instructions {
      position: static;
      color: @color-dark;
      margin-top: 30px;

      p {
        margin-bottom: 5px;
      }
    }

    .filters-instructions {
      top: 80px;
      left: 5%;
      width: 80%;

      img {
        display: none;
      }
    }

    .filters-icon {
      border: 1px solid @white;
      font-size: 22px;
      color: @white;
      position: fixed;
      width: 68px;
      height: 68px;
      text-align: center;
      line-height: 60px;
      top: 0;
      right: 45px;
      background: @color-orange;
    }
  }
}

@media @mobile {
  body {
    &.book-us-page {
      #footer {
        padding-top: 0;
        max-height: 170px;

        .book-us {
          display: none;
        }
      }
    }
  }
}
