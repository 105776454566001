@import (reference) "variables";

i, em {
  font-style: italic;
}

b, strong {
  font-weight: bold;
}

blockquote {
  text-align: cetner;
  font-family: @font-serif;
}

a {
  text-decoration: none;
  color: @color-link;

  &:hover {
    transition: all .2s ease-out 0s;
    color: @color-link-hover;
  }

  &:visited {
    color: @color-link-visited;
  }

  &:focus {
    outline: none;
  }
}

.text-center {
  text-align: center;
}

h1 {
  .font-size(50);
  .line-height(50);

  font-family: @font-bebas;
  color: @color-dark;
  margin-bottom: 30px; /*check it*/
  word-spacing: 4px;

  &.smaller-on-mobile {
    @media @mobile {
      .font-size(26);
      .line-height(26);
    }
  }
}

h2 {
  color: @color-dark;

  &.page-title {
    .font-size(22);

    letter-spacing: 1px;
    margin-bottom: 50px;

    span {
      position: relative;
      display: inline-block;
      font-family: @font-bebas;

      &:after {
        .size(100%, 1px);

        display: block;
        background: @color-title-border;
        position: absolute;
        left: 0;
        bottom: -25px;
        content: "";
      }
    }
  }

  @media @mobile {
    &.page-title {
      .font-size(20);
    }
  }
}

h3 {
  .font-size(18);
  .line-height(22);
}

.all-link {
  .font-size(13);

  font-weight: bold;
  text-transform: uppercase;
}

.breadcrumbs {
  max-width: 100%;
  height: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  li {
    display: inline-block;
    color: @color-link;

    a {
      padding-right: 10px;
      position: relative;
      font-family: @font-helvetica;
      max-width: 130px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      color: @color-dark;

      &.breadcrumb-url {
        color: @color-link;
      }

      &:after {
        content: '\203a';
        position: absolute;
        right: 3px;
        top: 0;
        display: block;
        color: @black;
      }
    }

    &:last-of-type {
      a {
        padding-right: 0;

        &:after {
          display: none;
        }
      }
    }
  }

  @media @mobile {
    display: none;
  }
}
